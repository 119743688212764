import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useContext, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import AppContext from "@/context/AppContext";

export default function DeleteWebsite({ onCloseDialog, onDeleteWebsite }) {
  const appcontext = useContext(AppContext);

  const t = appcontext.t;
  const locale = appcontext.locale;
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);


  const handleClose = () => {
    setOpen(false);
    onCloseDialog();
  };
  const deleteWebsite = () => {
    setOpen(false);
    setLoading(true);
    onDeleteWebsite();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className="text-lg font-semibold text-center">
        {t.DeleteWebsite}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText>
          {t.DeleteWebsiteMsg}
        </DialogContentText>
        <div className='flex w-full items-center justify-center'>
          <Button variant="outlined" className="btn-outline-grey mr-5 text-normal" onClick={handleClose}>{t.Cancel}</Button>
          <Button variant="contained" className="btn-red text-normal" onClick={deleteWebsite}>{loading ? t.Deleting : t.Delete}</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

