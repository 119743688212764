import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useContext, useEffect, useRef, useState } from "react";


// Module
import axios from "@/common/libs/axios";
import { useAuth } from "@/common/hooks/auth";
import AppContext from "@/context/AppContext";
import WebisteTab from './history/websiteTab.js';
import DocumentTab from './history/documentTab.js';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteAllHistory from "@/common/components/elements/Forms/EnterpriseHistoryForm/DeleteAllHistory";
import EnterpriseLayout from "@/common/components/elements/Layout/EnterpriseLayout.js";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <>
      {value === index &&
        (
          <div className="mt-20">
            {index === 0 && <DocumentTab history={props.history} loadingHistory={props.loadingHistory} onDeleteDocumentSuccess={props.onDeleteDocumentSuccess} />}
            {index === 1 && <WebisteTab websites={props.websites} loadingWebsites={props.loadingWebsites} onDeleteWebsiteSuccess={props.onDeleteWebsiteSuccess} onDeleteAllWebsiteSuccess={props.onDeleteAllWebsiteSuccess} />}
          </div>
        )
      }
    </>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
export default function Home() {
  const { user } = useAuth({
    middleware: "auth",
  });
  const appcontext = useContext(AppContext);
  const t = appcontext.t;

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [history, setHistory] = useState([1, 2, 3, 4, 5]);
  const [websites, setWebsites] = useState([1, 2, 3, 4, 5]);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [loadingWebsites, setLoadingWebsites] = useState(true);
  const [showClearHistory, setShowClearHistory] = useState(false);

  const handleChange = (event, newValue) => {
    setActiveTabIndex(newValue);
  };

  const onDeleteDocumentSuccess = (file) => {
    const deletedIndex = history.findIndex(document => document.id === file.id);
    history.splice(deletedIndex, 1);
    setHistory(history.length ? history : []);
  };

  const onDeleteWebsiteSuccess = (data) => {
    if (websites[data.parentIndex]?.pages?.length > 1) {
      websites[data.parentIndex].pages.splice(data.childIndex, 1);
    } else {
      websites.splice(data.parentIndex, 1);
    }
    setWebsites(websites.length ? websites : []);
  };

  const onDeleteAllWebsiteSuccess = (file, index) => {
    websites.splice(index, 1);
    setWebsites(websites.length ? websites : []);
  };

  useEffect(() => {
    axios
      .get("/api/user/team/translation-history")
      .then((res) => {
        setHistory(res.data.data);
        setLoadingHistory(false);
      })
      .catch((error) => {
        setHistory([]);
        setLoadingHistory(false);
        if (error?.response?.status !== 409) throw error;
      })
    axios
      .get("/api/user/document/translation-history")
      .then((res) => {
        res.data.data.forEach(web => {
          web.tempPages = web.pages;
          web.searching = false;
          web.searchText = "";
          web.source = web?.model?.split('-')[0];
          web.target = web?.model?.split('-')[1];
          //web.extraction_type = web.pages.length > 1 ? 'entire' : 'single';
        });
        setWebsites(res.data.data);
        setLoadingWebsites(false);
      })
      .catch((error) => {
        setWebsites([]);
        setLoadingWebsites(false);
        if (error?.response?.status !== 409) throw error;
      })
  }, []);

  const clearHistory = () => {
    if (loadingHistory || loadingWebsites ||
      (activeTabIndex === 0 && !history.length) ||
      (activeTabIndex === 1 && !websites.length)
    ) {
      return;
    } else {
      setShowClearHistory(true);
    }
  };

  const onCloseDialog = () => {
    setShowClearHistory(false);
  };
  const onClearHistoryConfrim = () => {
    if (activeTabIndex === 0) {
      setLoadingHistory(true);
      axios
        .post("/api/user/translation-history/delete/all")
        .then((res) => {
          setHistory([]);
          setLoadingHistory(false);
        })
        .catch((error) => {
          setLoadingHistory(false);
          if (error?.response?.status !== 409) throw error;
        })

    } else {
      setLoadingWebsites(true);
      axios
        .post("/api/user/delete/all-domains-by-user")
        .then((res) => {
          setWebsites([]);
          setLoadingWebsites(false);
        })
        .catch((error) => {
          setLoadingWebsites(false);
          if (error?.response?.status !== 409) throw error;
        })
    }
  };
  return (
    <EnterpriseLayout
      usr={user}
      className="mb-[50px] md:mb-[140px] lg:mb-[160px] xl:mb-[175px]"
      title="Tarjama AMT - The best Arabic translation tool"
      description="Best online translator - translate your text and document with Tarjama translator"
    >
      <Box sx={{ width: '100%' }} className="flex flex-col">
        <div className="flex justify-between items-center mb-20 md:mb-0">
          <p className="font-semibold text-lg mb-0 md:mb-20">{t.History}</p>
          <div className={`flex items-center cursor-pointer md:hidden ${loadingHistory || loadingWebsites || (!history.length && activeTabIndex === 0) || (!websites.length && activeTabIndex === 1) ? "cursor-auto" : "cursor-pointer"}`} onClick={() => clearHistory()}>
            <DeleteOutlineOutlinedIcon color={loadingHistory || loadingWebsites || (!history.length && activeTabIndex === 0) || (!websites.length && activeTabIndex === 1) ? "grey" : "black"} className="mr-5 cursor-pointer font-bold" />
            <p className={`font-bold ${loadingHistory || loadingWebsites || (!history.length && activeTabIndex === 0) || (!websites.length && activeTabIndex === 1) ? "text-grey" : "text-black"}`}>{t.clearHistory}</p>
          </div>
        </div>
        <div className="flex  justify-center md:justify-between items-center">
          <Tabs
            value={activeTabIndex}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            <Tab label={t.Documents} {...a11yProps(0)} />
            <Tab label={t.Websites}  {...a11yProps(1)} />
          </Tabs>
          <div className={`hidden md:flex items-center ${loadingHistory || loadingWebsites || (!history.length && activeTabIndex === 0) || (!websites.length && activeTabIndex === 1) ? "cursor-auto" : "cursor-pointer"}`} onClick={() => clearHistory()}>
            <DeleteOutlineOutlinedIcon color={loadingHistory || loadingWebsites || (!history.length && activeTabIndex === 0) || (!websites.length && activeTabIndex === 1) ? "disabled" : "black"} className="mr-5 cursor-pointer" />
            <p className={`font-semibold text-black ${loadingHistory || loadingWebsites || (!history.length && activeTabIndex === 0) || (!websites.length && activeTabIndex === 1) ? "text-grey" : "text-black"}`}>{t.clearHistory}</p>
          </div>
        </div>
      </Box>
      <div>
        <TabPanel value={activeTabIndex} index={0} loadingHistory={loadingHistory} history={history} onDeleteDocumentSuccess={onDeleteDocumentSuccess} />
        <TabPanel value={activeTabIndex} index={1} loadingWebsites={loadingWebsites} websites={websites} onDeleteWebsiteSuccess={onDeleteWebsiteSuccess} onDeleteAllWebsiteSuccess={onDeleteAllWebsiteSuccess} />
      </div>
      {showClearHistory && <DeleteAllHistory onCloseDialog={onCloseDialog} onClearHistoryConfrim={onClearHistoryConfrim} />}
    </EnterpriseLayout>
  );
}
