import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Input from '@mui/material/Input';
import Skeleton from '@mui/material/Skeleton';
import AppContext from "@/context/AppContext";
import EastIcon from '@mui/icons-material/East';
import Accordion from '@mui/material/Accordion';
import SearchIcon from '@mui/icons-material/Search';
import LanguageIcon from '@mui/icons-material/Language';
import InputAdornment from '@mui/material/InputAdornment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from '@mui/material/CircularProgress';
import { useContext, useEffect, useRef, useState } from "react";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import axios from "@/common/libs/axios";
import Toast from "@/common/components/elements/ToastPopup/ToastPopup";
import DeleteHistoryWebsite from "@/common/components/elements/Forms/EnterpriseHistoryForm/DeleteHistoryWebsite";

import { isMobile } from 'react-device-detect';

export default function website(props) {

  const appcontext = useContext(AppContext);
  const t = appcontext.t;
  const isArabic = appcontext.locale === "ar";

  const [open, setOpen] = useState(false);
  const [toastMsg, seToastMsg] = useState('');
  const [searching, setSearching] = useState(false);
  const [deletedIndex, setDeletedIndex] = useState(null);
  const [downloadedIndex, setDownloadedIndex] = useState(null);
  const [selectedWebsite, setSelectedWebsite] = useState(null);
  const [downloaodLoading, setDownloaodLoading] = useState(false);
  const [showDeleteWebsite, setShowDeleteWebsite] = useState(false);
  const [disableDeleteWebsite, setDisableDeleteWebsite] = useState(false);
  const [disableDownloadWebsite, setDisableDownloadWebsite] = useState(false);



  const filterWebpage = (file, name) => {
    file.searchText = name;
    setSearching(true);
    if (!name.length) {
      file.tempPages = file.pages;
    } else {
      file.tempPages = file.pages.filter(page => page.name.includes(name));
    }
    setTimeout(() => {
      setSearching(false);
    }, 3000);
  };
  const handleToastClose = () => {
    setOpen(false);
  };

  const DownloadAllPages = (file, index) => {
    if (deletedIndex || deletedIndex === 0) {
      return
    }
    setDownloadedIndex(index);
    setDisableDeleteWebsite(true);
    setDisableDownloadWebsite(true);
    axios.post(`api/user/download/all`, { project_id: file.project_id }, { responseType: "blob" })
      .then((res) => {
        setDownloadedIndex(null);
        setDisableDeleteWebsite(false);
        setDisableDownloadWebsite(false);
        downloadFileData(res.data, 'website.zip', 'text/csv;application/vnd.ms-excel;application/vnd.ms-excel;charset=utf-8', true);
      })
      .catch((error) => {
        setOpen(true);
        setDownloadedIndex(null);
        setDisableDeleteWebsite(false);
        setDisableDownloadWebsite(false);
        seToastMsg('Failed to download Website');
        if (error?.response?.status !== 409) throw error;
      })
  };
  const downloadFileData = (data, filename, mime, iszip = false) => {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should

    const blob = new Blob([data], { type: iszip ? 'application/zip' : mime || 'text/csv;charset=utf-8' });

    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      // IE doesn't allow using a blob object directly as link href.
      // Workaround for "HTML7007: One or more blob URLs were
      // revoked by closing the blob for which they were created.
      // These URLs will no longer resolve as the data backing
      // the URL has been freed."
      window.navigator.msSaveBlob(blob, filename);
      return;
    }


    // Other browsers
    // Create a link pointing to the ObjectURL containing the blob
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(blobURL);
    }, 100);
  }

  const DeleteAllPages = (file, index) => {
    if (downloadedIndex || downloadedIndex === 0) {
      return;
    }
    setDeletedIndex(index);
    setDisableDeleteWebsite(true);
    setDisableDownloadWebsite(true);
    axios
      .post(`api/user/document/remove-all`, { project_id: file.project_id })
      .then((res) => {
        setDeletedIndex(null);
        setDisableDeleteWebsite(false);
        setDisableDownloadWebsite(false);
        props.onDeleteAllWebsiteSuccess(file, index);
      })
      .catch((error) => {
        setOpen(true);
        setDeletedIndex(null);
        setDisableDeleteWebsite(false);
        setDisableDownloadWebsite(false);
        seToastMsg('Failed to delete Website');
        if (error?.response?.status !== 409) throw error;
      })
  };

  const downloadWebsite = (file) => {
    setSelectedWebsite(file);
    setDownloaodLoading(true);
    axios
      .get(`api/user/download/file/${file.token}`, { responseType: 'blob' })
      .then((res) => {
        downloadFile(res.data, file.name);
      })
      .catch((error) => {
        setOpen(true);
        seToastMsg('Failed to download Website');
        setDownloaodLoading(false);
        if (error?.response?.status !== 409) throw error;
      })
  };

  const downloadFile = (url, name) => {
    let fileURL = window.URL.createObjectURL(new Blob([url]));
    let fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', name);
    document.body.appendChild(fileLink);
    fileLink.click();
    setDownloaodLoading(false);
    setOpen(true);
    seToastMsg('Website downloaded successfully');
  };

  const deleteWebsite = (file, parentIndex, childIndex) => {
    setSelectedWebsite({ file, parentIndex, childIndex });
    setShowDeleteWebsite(true);
  };

  const onCloseDialog = () => {
    setShowDeleteWebsite(false);
  };

  const onDeleteWebsite = () => {
    axios
      .post('api/user/document/remove', { project_id: selectedWebsite.file.project_id, document_id: selectedWebsite.file.id })
      .then((res) => {
        props.onDeleteWebsiteSuccess(selectedWebsite);
        setShowDeleteWebsite(false);
        setOpen(true);
        seToastMsg('Website deleted successfully');
      })
      .catch((error) => {
        seToastMsg('Failed to delete website');
        if (error?.response?.status !== 409) throw error;
      })
  };

  return (
    <>
      {!props.loadingWebsites && !props.websites?.length ? <p>{t.HistoryWebsitesIsEmpty}</p>
        :
        props.websites.map((file, index) => {
          return (
            <>
              <div className={`flex border rounded border-grey200 justify-between flex-col md:flex-row mb-20 ${file.pages?.length ? 'p-1' : 'p-5'}`} key={index}>
                {props.loadingWebsites ? <>
                  <div className="flex items-center w-full justify-between">
                    <div className="flex items-center w-1/3">
                      <InsertDriveFileIcon className="mr-5" fontSize={isMobile ? 'small' : 'large'} />
                      <div className="flex flex-col w-full">
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      </div>
                    </div>
                    <div className='flex'>
                      <Skeleton variant="rectangular" width={20} height={20} className="mr-5" />
                      <Skeleton variant="rectangular" width={20} height={20} />
                    </div>
                  </div>
                </> :
                  <>
                    {file.extraction_type === 'entire' ?
                      <Accordion className='w-full shadow-none'>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <div className="flex items-center">
                            <LanguageIcon fontSize="large" className="mr-5" />
                            <div className="flex flex-col">
                              <div className="flex mb-5 items-center">
                                <p className={`font-semibold ${isArabic ? 'ml-20' : 'mr-20'}`}>{file.domain_name}</p>
                                <li className="text-sm list-disc text-nowrap text-black font-semibold">{file.pages?.length} {t.Pages}</li>
                              </div>
                              <ul className="flex text-sm text-grey800 list-disc flex-row">
                                <li className={`list-disc flex items-center capitalize mr-20 ${isArabic ? 'md:ml-20 md:mr-0' : 'md:mr-20 md:ml-0'}`}>
                                  {file.source}
                                  <EastIcon className="text-sm" fontSize={isMobile ? 'small' : 'large'} />
                                  {file.target}
                                </li>
                                <div className="flex text-sm md:text-base">
                                  <li className={`hidden md:block ${isArabic ? 'ml-20' : 'mr-20'}`} >{file.createdAt}</li>
                                  <li className={`block md:block ${isArabic ? 'ml-20' : 'mr-20'}`} >{file.word_count} {t.Words}</li>
                                  <li className="list-none">{file.createdBy}</li>
                                </div>
                              </ul>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="flex justify-between items-center flex-col md:flex-row">
                            <Input
                              className="w-full md:w-1/3 rounded-xl"
                              id="outlined-basic"
                              placeholder={t.SearchByPageName}
                              variant="outlined"
                              value={file.searchText}
                              onChange={(event) => filterWebpage(file, event.target.value)}
                              startAdornment={
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              }
                            />
                            {!file.searchText?.length && <div className="flex justify-start md:justify-end items-center w-full md:w-1/3 mt-5 md:mt-0">
                              <div
                                className={`items-center text-sm md:text-md flex font-semibold cursor-pointer ${disableDownloadWebsite ? 'text-grey200' : 'text-black'} ${isArabic ? 'ml-20' : 'mr-20'}`}
                                onClick={() => DownloadAllPages(file, index)}
                              >
                                {downloadedIndex === index ? <CircularProgress color="inherit" size={20} /> : <FileDownloadOutlinedIcon />}
                                {t.DownloadAll}
                              </div>
                              <div
                                className={`items-center text-sm md:text-md flex font-semibold cursor-pointer ${disableDeleteWebsite ? 'text-grey200' : 'text-black'}`}
                                onClick={() => DeleteAllPages(file, index)}
                              >
                                {deletedIndex === index ? <CircularProgress color="inherit" size={20} /> : <DeleteOutlineOutlinedIcon color="black" />}
                                {t.DeleteAll}
                              </div>
                            </div>}
                          </div>
                          {searching && file.searchText?.length ? <p className="mt-5">{t.Searching}</p> :
                            file.tempPages.map((subfile, childIndex) => {
                              return (
                                <>
                                  <div className="flex items-center justify-between w-full border-b pt-5 pb-2" key={childIndex}>
                                    <div className="flex items-center w-1/2">
                                      <InsertDriveFileIcon className="mr-5" fontSize={isMobile ? 'small' : 'large'} />
                                      <div className="flex mb-0 md:mb-5 items-center">
                                        <p className={`font-semibold ${isArabic ? 'ml-20' : 'mr-20'}`}>{subfile.name}</p>
                                        {/* <li className="text-sm list-disc hidden md:block text-nowrap text-black font-semibold">{subfile.pages?.length} {t.Pages}</li> */}
                                      </div>
                                    </div>
                                    <div className="flex items-center w-1/2 justify-end">
                                      {selectedWebsite?.id === subfile.id && downloaodLoading ?
                                        <CircularProgress color="inherit" size={20} />
                                        :
                                        <FileDownloadOutlinedIcon className="mr-5 cursor-pointer text-green" onClick={() => downloadWebsite(subfile)} />
                                      }
                                      <DeleteOutlineOutlinedIcon color="error" className="mr-5 cursor-pointer" onClick={() => deleteWebsite(subfile, index, childIndex)} />
                                    </div>
                                  </div>
                                </>
                              )
                            })
                          }
                        </AccordionDetails>
                      </Accordion>
                      :
                      <>
                        <div className="flex items-center ps-5">
                          <InsertDriveFileIcon className="mr-5" fontSize={isMobile ? 'small' : 'large'} />
                          <div className="flex flex-col">
                            <div className="flex mb-5 items-center">
                              <p className={`font-semibold ${isArabic ? 'ml-20' : 'mr-20'}`}>{file.domain_name}</p>
                              <li className="text-sm list-disc text-nowrap text-black font-semibold">1 {t.Page}</li>
                            </div>
                            <ul className="flex text-sm text-grey800 list-disc flex-col md:flex-row">
                              <div className="flex">
                                <li className={`list-disc flex items-center capitaliz ${isArabic ? 'mr-20 md:ml-20 md:mr-0 ' : 'ml-20 md:mr-20 md:ml-0 '}`}>
                                  {file.source}
                                  <EastIcon className="text-sm" fontSize={isMobile ? 'small' : 'large'} />
                                  {file.target}
                                </li>
                              </div>
                              <div className="flex text-sm md:text-base">
                                <li className={`hidden md:block ${isArabic ? 'ml-20' : 'mr-20'}`} >{file.createdAt}</li>
                                <li className={`block md:block ${isArabic ? 'ml-20' : 'mr-20'}`} >{file.word_count} {t.Words}</li>
                                <li className='list-none'>{file.createdBy}</li>
                              </div>
                            </ul>
                          </div>
                        </div>
                        <div className="flex items-center">
                          {selectedWebsite?.id === file.id && downloaodLoading ?
                            <CircularProgress color="inherit" size={20} />
                            :
                            <FileDownloadOutlinedIcon className="mr-5 cursor-pointer text-green" onClick={() => downloadWebsite(file.pages[0])} />
                          }
                          <DeleteOutlineOutlinedIcon color="error" className="mr-5 cursor-pointer" onClick={() => deleteWebsite(file.pages[0], index, 0)} />
                        </div>
                      </>
                    }
                  </>
                }
              </div>
              {showDeleteWebsite && selectedWebsite?.parentIndex === index && <DeleteHistoryWebsite onCloseDialog={onCloseDialog} onDeleteWebsite={onDeleteWebsite} />}
            </>
          )
        })
      }
      <Toast open={open} toastMsg={toastMsg} handleClose={handleToastClose} />
    </>
  );
}
