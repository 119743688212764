
import Skeleton from '@mui/material/Skeleton';
import AppContext from "@/context/AppContext";
import EastIcon from '@mui/icons-material/East';
import CircularProgress from '@mui/material/CircularProgress';
import { useContext, useEffect, useRef, useState } from "react";

import axios from "@/common/libs/axios";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Toast from "@/common/components/elements/ToastPopup/ToastPopup";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteHistoryDocument from "@/common/components/elements/Forms/EnterpriseHistoryForm/DeleteHistoryDocument";

export default function history(props) {

  const appcontext = useContext(AppContext);
  const t = appcontext.t;

  const [open, setOpen] = useState(false);
  const [toastMsg, seToastMsg] = useState('');
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [downloaodLoading, setDownloaodLoading] = useState(false);
  const [showDeleteDocument, setShowDeleteDocument] = useState(false);
  const handleToastClose = () => {
    setOpen(false);
  };

  const downloadDocument = (file) => {
    setSelectedDocument(file);
    setDownloaodLoading(true);
    axios
      .get(`api/translated-file-url/?id=${file.id}`, { responseType: 'blob' })
      .then((res) => {
        downloadFile(res.data, file.name);
        setShowDeleteDocument(false);
      })
      .catch((error) => {
        setOpen(true);
        seToastMsg('Failed to download Document');
        setDownloaodLoading(false);
        if (error?.response?.status !== 409) throw error;
      })
  };

  const downloadFile = (url, name) => {
    let fileURL = window.URL.createObjectURL(new Blob([url]));
    let fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', name);
    document.body.appendChild(fileLink);
    fileLink.click();
    setDownloaodLoading(false);
    setOpen(true);
    seToastMsg('Document downloaded successfully');
  };

  const deleteDocument = (file) => {
    setSelectedDocument(file);
    setShowDeleteDocument(true);
  };

  const onCloseDialog = () => {
    setShowDeleteDocument(false);
  };

  const onDeleteDocument = () => {
    axios
      .post('api/user/translation-history/delete', { project_id: selectedDocument.project_id })
      .then((res) => {
        props.onDeleteDocumentSuccess(selectedDocument);
        setShowDeleteDocument(false);
        setOpen(true);
        seToastMsg('Document deleted successfully');
      })
      .catch((error) => {
        seToastMsg('Failed to delete document');
        if (error?.response?.status !== 409) throw error;
      })
  };

  return (
    <>
      {!props.loadingHistory && !props.history?.length ? <p>{t.HistoryDocumentsIsEmpty}</p>
        :
        props.history.map((file, index) => {
          return (
            <>
              <div className="flex border rounded border-grey200 p-5 justify-between flex-col md:flex-row mb-20" key={index}>
                {props.loadingHistory ? <>
                  <div className="flex items-center w-full justify-between">
                    <div className="flex items-center w-1/3">
                      <InsertDriveFileIcon className="mr-5" fontSize="large" />
                      <div className="flex flex-col w-full">
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      </div>
                    </div>
                    <div className='flex'>
                      <Skeleton variant="rectangular" width={20} height={20} className="mr-5" />
                      <Skeleton variant="rectangular" width={20} height={20} />
                    </div>
                  </div>
                </> :
                  <>
                    <div className="flex items-center">
                      <InsertDriveFileIcon className="mr-5" fontSize="large" />
                      <div className="flex flex-col">
                        <div className="flex mb-5 items-center">
                          <p className="font-semibold mr-20">{file.name}</p>
                          <li className="text-sm list-disc hidden md:block text-black font-semibold">{file.word_count} {t.Words}</li>
                        </div>
                        <ul className="flex text-sm text-grey800 list-disc flex-col md:flex-row">
                          <div className="flex">
                            <li className="text-base list-disc block md:hidden font-semibold text-black">{file.word_count} {t.Words}</li>
                            <li className="ml-20 md:mr-20 md:ml-0 list-disc flex items-center capitalize">
                              {file.source}
                              <EastIcon className="text-sm" />
                              {file.target}
                            </li>
                          </div>
                          <div className="flex text-base">
                            <li className="mr-20" >{file.createdAt}</li>
                            <li>{file.user_name}</li>
                          </div>
                        </ul>
                      </div>
                    </div>
                    <div className="flex items-center mt-20 md:mt-0">
                      <p className="text-lightgrey text-sm mr-5">243 KB</p>
                      {selectedDocument?.id === file.id && downloaodLoading ?
                        <CircularProgress color="inherit" size={20} />
                        :
                        <FileDownloadOutlinedIcon className="mr-5 cursor-pointer text-green" onClick={() => downloadDocument(file)} />
                      }
                      <DeleteOutlineOutlinedIcon color="error" className="mr-5 cursor-pointer" onClick={() => deleteDocument(file)} />
                    </div>
                  </>
                }
              </div>
              {showDeleteDocument && <DeleteHistoryDocument onCloseDialog={onCloseDialog} onDeleteDocument={onDeleteDocument} />}
            </>
          )
        })
      }
      <Toast open={open} toastMsg={toastMsg} handleClose={handleToastClose} />
    </>
  );
}
